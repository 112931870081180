import { combineReducers } from 'redux';

// UI
import formReducer from './ui/form';
import lastActionReducer from './ui/last-action';
import dialogsReducer from './ui/dialogs';

// Core
import authReducer from './auth';
import organizationFinderReducer from './organization-finder';
import organizationReducer from './organization.reducer';
import addressFinderReducer from './address-finder.reducer';
import rgeCertificatesFinderReducer from './rge-certificates-finder.reducer';
import subContractorsReducer from './sub-contractors.reducer';
import contractReducer from './contracts.reducer';
import fiscalInformationFinderReducer from './fiscal-information-finder.reducer';
import customerPPReducer from './customer-pp.reducer';
import quotationReducer from './quotation.reducer';
import customerPMReducer from './customer-pm.reducer';
import renovationAddressReducer from './renovation-address.reducer';
import operationSheetReducer from './operation-sheet.reducer';
import productReducer from './product.reducer';
import invoiceReducer from './invoice.reducer';
import dossierCEEReducer from './dossier-cee.reducer';
import uploadDocumentReducer from './upload-document.recuder';
import consumerMediatorReducer from './consumer-mediator.reducer';
import productCatalogueState from './product-catalogue.reducer';

export default combineReducers({
    auth: authReducer,
    organizationFinder: organizationFinderReducer,
    organization: organizationReducer,
    addressFinder: addressFinderReducer,
    rgeCertificatesFinder: rgeCertificatesFinderReducer,
    subContractors: subContractorsReducer,
    contracts: contractReducer,
    fiscalInformationFinder: fiscalInformationFinderReducer,
    customersPPState: customerPPReducer,
    quotationsState: quotationReducer,
    customersPMState: customerPMReducer,
    renovationAddressesState: renovationAddressReducer,
    operationSheetsState: operationSheetReducer,
    productsState: productReducer,
    invoicesState: invoiceReducer,
    dossiersCEEState: dossierCEEReducer,
    uploadDocumentsState: uploadDocumentReducer,
    consumerMediatorsState: consumerMediatorReducer,
    productsCatalogueState: productCatalogueState,
    ui: combineReducers({
        form: formReducer,
        lastAction: lastActionReducer,
        dialogs: dialogsReducer,
    }),
});
