import jwt from 'jsonwebtoken';

// Auth
const TOKEN_KEY = 'token_key';

const isAuthenticate = () => !!localStorage.getItem(TOKEN_KEY);

const setToken = ({ token }) => localStorage.setItem(TOKEN_KEY, token);

const getToken = () => localStorage.getItem(TOKEN_KEY);

// Organization UUID
const ORGANIZATION_UUID_KEY = 'ORGANIZATION_UUID_KEY';

const isComplete = () => !!localStorage.getItem(ORGANIZATION_UUID_KEY);

const setOrganizationUUID = ({ uuidOrganization }) =>
    localStorage.setItem(ORGANIZATION_UUID_KEY, uuidOrganization);

const getOrganizationUUID = () => localStorage.getItem(ORGANIZATION_UUID_KEY);

// Clear
const clearAll = () => {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(ORGANIZATION_UUID_KEY);
};

const getAccountId = () => {
    let payload;
    const token = getToken();
    if (token) payload = jwt.decode(token);
    return payload.sub;
};

export default {
    isAuthenticate,
    setToken,
    getToken,
    clearAll,
    getAccountId,
    isComplete,
    setOrganizationUUID,
    getOrganizationUUID
};
