import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

import storage from '../../utils/storage';

const TypedRoute = ({
    component: Component,
    type,
    isMenuItem,
    ...remainingProps
}) => (
    <Route
        {...remainingProps}
        render={(props) =>
            type === 'private' ? (
                storage.isAuthenticate() ? (
                    <Component {...props} />
                ) : (
                    <Redirect to='/connexion' />
                )
            ) : !storage.isAuthenticate() ? (
                <Component {...props} />
            ) : remainingProps.path.includes('validation') ? (
                <Component {...props} />
            ) : (
                <Redirect to='/' />
            )
        }
    />
);

TypedRoute.propTypes = {
    type: PropTypes.oneOf(['public', 'private', ''])
};

TypedRoute.defaultProps = {
    type: 'public'
};

export default TypedRoute;
