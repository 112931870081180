import { CREATE_CUSTOMER_PP_SUCCESS, GET_CUSTOMER_PP_SUCCESS, GET_CUSTOMERS_PP_SUCCESS, UPDATE_CUSTOMER_PP_SUCCESS, DELETE_CUSTOMER_PP_SUCCESS } from '../types/customer-pp.types';

function initState() {
    return {
        customerPP: {},
        customersPP: [],
    };
}

export default function (state = initState(), action) {
    switch (action.type) {
        case CREATE_CUSTOMER_PP_SUCCESS:
            return {
                ...state,
                customerPP: action.payload.customerPP,
            };

        case GET_CUSTOMER_PP_SUCCESS:
            return {
                ...state,
                customerPP: action.payload.customerPP,
            };

        case GET_CUSTOMERS_PP_SUCCESS:
            return {
                ...state,
                customersPP: action.payload.customersPP,
            };

        case UPDATE_CUSTOMER_PP_SUCCESS:
            return {
                ...state,
                customerPP: action.payload.customerPP,
            };

        case DELETE_CUSTOMER_PP_SUCCESS:
            return {
                ...state,
                customersPP: state.customersPP.filter((customerPP) => customerPP.uuid !== action.payload.customerPP.uuid),
            };

        default:
            return state;
    }
}
