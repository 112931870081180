import {
    CREATE_QUOTATION_SUCCESS,
    GET_QUOTATIONS_REQUEST,
    GET_QUOTATIONS_SUCCESS,
    GET_QUOTATION_SUCCESS,
    PATCH_QUOTATION_SUCCESS,
    ADD_OPERATION,
    UPDATE_OPERATION,
    RESET_OPERATION,
    DELETE_OPERATION,
    ADD_PRODUCT,
    UPDATE_MAIN_PRODUCT,
    UPDATE_OTHER_PRODUCT,
    DELETE_OTHER_PRODUCT,
    DELETE_ALL_OTHER_PRODUCTS,
    UPDATE_OPERATION_INSTALLATION_DATE,
    UPDATE_OPERATION_TECHNICIAN,
    PRE_SAVE_QUOTATION_SUCCESS,
    UPDATE_QUOTATION,
    SAVE_QUOTATION_SUCCESS,
    GET_QUOTATIONS_ANALYTICS_SUCCESS,
    SEND_QUOTATION_BY_EMAIL_SUCCESS,
    SEND_QUOTATION_BY_EMAIL_FAILURE,
} from '../types/quotation.types';
import { productTypes } from '../../utils/enums';

function initState() {
    return {
        quotations: [],
        quotation: {},
        analytics: [],
    };
}

export default function (state = initState(), action) {
    switch (action.type) {
        case CREATE_QUOTATION_SUCCESS:
            return {
                ...state,
                quotation: action.payload.quotation,
            };

        case GET_QUOTATIONS_REQUEST:
            return {
                ...state,
                quotations: [],
            };

        case GET_QUOTATIONS_SUCCESS:
            return {
                ...state,
                quotations: action.payload.quotations,
            };

        case GET_QUOTATION_SUCCESS:
            return {
                ...state,
                quotation: action.payload.quotation,
            };

        case PATCH_QUOTATION_SUCCESS:
            return {
                ...state,
                quotation: {
                    ...action.payload.quotation,
                    operations: state.quotation.operations.map((operation) => {
                        const matchingOperation = action.payload.quotation.operations.find((op) => op.uuid === operation.uuid) || {};
                        const updatedOperation = {
                            ...operation,
                            ...matchingOperation,
                        };
                        if (!matchingOperation.compliant) delete updatedOperation.operationVolumesAndPremium;

                        return updatedOperation;
                    }),
                },
            };

        case ADD_OPERATION:
            return {
                ...state,
                quotation: {
                    ...state.quotation,
                    operations: [...state.quotation.operations, action.payload.operation],
                },
            };

        case UPDATE_OPERATION:
            return {
                ...state,
                quotation: {
                    ...state.quotation,
                    operations: state.quotation.operations.map((operation) => {
                        if (operation.uuid === action.payload.operationUUID) {
                            return {
                                ...operation,
                                ...action.payload.updates,
                            };
                        }

                        return operation;
                    }),
                },
            };

        case RESET_OPERATION:
            return {
                ...state,
                quotation: {
                    ...state.quotation,
                    operations: state.quotation.operations.map((operation) => {
                        if (operation.uuid === action.payload.operationUUID) return action.payload.operation;

                        return operation;
                    }),
                },
            };

        case DELETE_OPERATION:
            return {
                ...state,
                quotation: {
                    ...state.quotation,
                    operations: state.quotation.operations.filter(({ uuid }) => uuid !== action.payload.operationUUID),
                },
            };

        case ADD_PRODUCT:
            return {
                ...state,
                quotation: {
                    ...state.quotation,
                    operations: state.quotation.operations.map((operation) => {
                        if (operation.uuid === action.payload.operationUUID) {
                            const { products = [] } = operation;
                            products.push(action.payload.product);

                            return {
                                ...operation,
                                products,
                            };
                        }

                        return operation;
                    }),
                },
            };

        case UPDATE_MAIN_PRODUCT:
            return {
                ...state,
                quotation: {
                    ...state.quotation,
                    operations: state.quotation.operations.map((operation) => {
                        if (operation.uuid === action.payload.operationUUID) {
                            return {
                                ...operation,
                                products: operation.products.map((product) => {
                                    if (product.product.type === productTypes.MAIN_PRODUCT) {
                                        const updatedMainProduct = {
                                            ...product,
                                            ...action.payload.updates,
                                        };
                                        if (['quantity', 'price'].every((property) => Object.keys(updatedMainProduct).includes(property))) {
                                            updatedMainProduct.totalPrice = updatedMainProduct.quantity * updatedMainProduct.price;
                                        }

                                        return updatedMainProduct;
                                    }

                                    return product;
                                }),
                            };
                        }

                        return operation;
                    }),
                },
            };

        case UPDATE_OTHER_PRODUCT:
            return {
                ...state,
                quotation: {
                    ...state.quotation,
                    operations: state.quotation.operations.map((operation) => {
                        if (operation.uuid === action.payload.operationUUID) {
                            return {
                                ...operation,
                                products: operation.products.map((product) => {
                                    if (product.uuid === action.payload.productUUID) {
                                        const updatedProduct = {
                                            ...product,
                                            ...action.payload.updates,
                                        };
                                        if (['quantity', 'price'].every((property) => Object.keys(updatedProduct).includes(property))) {
                                            updatedProduct.totalPrice = updatedProduct.quantity * updatedProduct.price;
                                        }

                                        return updatedProduct;
                                    }

                                    return product;
                                }),
                            };
                        }

                        return operation;
                    }),
                },
            };

        case DELETE_OTHER_PRODUCT: {
            return {
                ...state,
                quotation: {
                    ...state.quotation,
                    operations: state.quotation.operations.map((operation) => {
                        if (operation.uuid === action.payload.operationUUID) {
                            return {
                                ...operation,
                                products: operation.products.filter(({ uuid }) => uuid !== action.payload.productUUID),
                            };
                        }

                        return operation;
                    }),
                },
            };
        }

        case DELETE_ALL_OTHER_PRODUCTS: {
            return {
                ...state,
                quotation: {
                    ...state.quotation,
                    operations: state.quotation.operations.map((operation) => {
                        if (operation.uuid === action.payload.operationUUID) {
                            return {
                                ...operation,
                                products: operation.products.filter(({ product }) => product.type !== productTypes.OTHER_PRODUCT),
                            };
                        }

                        return operation;
                    }),
                },
            };
        }

        case UPDATE_OPERATION_INSTALLATION_DATE:
            return {
                ...state,
                quotation: {
                    ...state.quotation,
                    operations: state.quotation.operations.map((operation) => {
                        if (operation.uuid === action.payload.operationUUID) {
                            return {
                                ...operation,
                                installationDate: action.payload.installationDate,
                            };
                        }

                        return operation;
                    }),
                },
            };

        case UPDATE_OPERATION_TECHNICIAN:
            return {
                ...state,
                quotation: {
                    ...state.quotation,
                    operations: state.quotation.operations.map((operation) => {
                        if (operation.uuid === action.payload.operationUUID) {
                            return {
                                ...operation,
                                technician: action.payload.technician,
                            };
                        }

                        return operation;
                    }),
                },
            };

        case PRE_SAVE_QUOTATION_SUCCESS:
            return {
                ...state,
                quotation: action.payload.quotation,
            };

        case UPDATE_QUOTATION:
            return {
                ...state,
                quotation: {
                    ...state.quotation,
                    ...action.payload.updates,
                },
            };

        case SAVE_QUOTATION_SUCCESS:
            return {
                ...state,
                quotation: action.payload.quotation,
            };

        case GET_QUOTATIONS_ANALYTICS_SUCCESS: {
            return {
                ...state,
                analytics: action.payload.analytics,
            };
        }

        case SEND_QUOTATION_BY_EMAIL_SUCCESS:
            return {
                ...state,
                quotation: action.payload.quotation,
            };

        case SEND_QUOTATION_BY_EMAIL_FAILURE:
            return {
                ...state,
                error: action.error,
            };

        default:
            return state;
    }
}
