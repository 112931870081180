import { GET_PRODUCTS_SUCCESS, GET_PRODUCTS_FAILURE } from '../types/product-catalogue.types';

const initState = () => ({
    product: {},
    products: [],
});

export default (state = initState(), action) => {
    switch (action.type) {
        case GET_PRODUCTS_SUCCESS:
            return {
                ...state,
                products: action.payload,
            };
        case GET_PRODUCTS_FAILURE:
            return {
                ...state,
            };

        default:
            return state;
    }
};
