import React from 'react';
import loadable from '@loadable/component';

const Dashboard = React.lazy(() => import('./components/pages/Dashboard'));
const Organization = React.lazy(() => import('./components/pages/Organization'));
const SubContractors = React.lazy(() => import('./components/pages/SubContractors'));
const Contracts = React.lazy(() => import('./components/pages/Contracts'));
const Customers = React.lazy(() => import('./components/pages/Customers'));
const Quotations = React.lazy(() => import('./components/pages/Quotations'));
const Invoices = React.lazy(() => import('./components/pages/Invoices'));
const Invoice = React.lazy(() => import('./components/pages/quotation/Quotation'));
const InvoiceEdition = React.lazy(() => import('./components/pages/invoice/InvoiceEdition'));
const InvoicePreview = React.lazy(() => import('./components/pages/invoice/InvoicePreview'));
const DossiersCEE = React.lazy(() => import('./components/pages/DossiersCEE'));
const Documents = React.lazy(() => import('./components/pages/Documents'));
const SubContractor = React.lazy(() => import('./components/pages/SubContractor'));
const Profile = React.lazy(() => import('./components/pages/Profile'));
const Customer = React.lazy(() => import('./components/pages/Customer'));
const Quotation = React.lazy(() => import('./components/pages/quotation/Quotation'));
const QuotationEdition = React.lazy(() => import('./components/pages/quotation/QuotationEdition'));
const QuotationPreview = React.lazy(() => import('./components/pages/quotation/QuotationPreview'));
const DossierCEE = React.lazy(() => import('./components/pages/DossierCEE'));
const Document = React.lazy(() => import('./components/pages/Document'));
const ProductCatalogue = React.lazy(() => import('./components/pages/ProductCatalogue'));

const privateRoutes = [
    {
        label: 'Tableau de bord',
        path: '/tableau-de-bord',
        icon: 'dashboard',
        component: Dashboard,
        isMenuItem: true,
    },
    {
        label: 'Mon organisation',
        path: '/organisation',
        icon: 'organization',
        component: Organization,
        isMenuItem: true,
    },
    {
        label: 'Mes sous-traitants',
        path: '/sous-traitants',
        icon: 'subcontractors',
        component: SubContractors,
        exact: true,
        isMenuItem: true,
    },
    {
        label: 'Mes contrats obligés',
        path: '/contrats',
        icon: 'contracts',
        component: Contracts,
        isMenuItem: true,
    },
    {
        label: 'Mes clients',
        path: '/clients',
        icon: 'customers',
        component: Customers,
        exact: true,
        isMenuItem: true,
    },
    {
        label: 'Catalogue produit',
        path: '/catalogue-produit',
        icon: '',
        component: ProductCatalogue,
        isMenuItem: true,
        exact: true,
    },
    {
        label: 'Mes devis',
        path: '/devis',
        icon: 'quotations',
        component: Quotations,
        isMenuItem: true,
        exact: true,
    },
    {
        label: 'Mes factures',
        path: '/factures',
        icon: 'invoices',
        component: Invoices,
        isMenuItem: true,
        exact: true,
    },
    {
        label: 'Facture',
        path: '/facture/:uuid',
        component: Invoice,
        exact: true,
    },
    {
        label: 'Edition facture',
        path: '/facture/:uuid/edition',
        component: InvoiceEdition,
    },
    {
        label: 'Apercu facture',
        path: '/facture/:uuid/apercu',
        component: InvoicePreview,
    },
    {
        label: 'Dossiers de financement',
        path: '/dossiers-financement',
        icon: 'certificates',
        component: DossiersCEE,
        isMenuItem: true,
        exact: true,
    },
    {
        label: 'Documents joints',
        path: '/documents',
        icon: 'documents',
        component: Documents,
        isMenuItem: true,
        exact: true,
    },
    {
        label: 'Fiche sous traitant',
        path: '/sous-traitants/:uuid',
        component: SubContractor,
    },
    {
        label: 'Profile',
        path: '/profil',
        component: Profile,
    },
    {
        label: 'Fiche client',
        path: '/clients/:uuid',
        component: Customer,
    },
    {
        label: 'Devis',
        path: '/devis/:uuid',
        component: Quotation,
        exact: true,
    },
    {
        label: 'Edition devis',
        path: '/devis/:uuid/edition',
        component: QuotationEdition,
    },
    {
        label: 'Apercu devis',
        path: '/devis/:uuid/apercu',
        component: QuotationPreview,
    },
    {
        label: 'Dossier financement',
        path: '/dossiers-financement/:uuid',
        component: DossierCEE,
    },
    {
        label: 'Document',
        path: '/documents/:uuid',
        component: Document,
    },
];

const sidebarMenuItems = privateRoutes.filter((privateRoute) => privateRoute.isMenuItem).map(({ component, ...sidebarMenuItem }) => sidebarMenuItem);

const Login = loadable(() => import('./components/pages/Login'));
const Register = loadable(() => import('./components/pages/RegisterV2'));
const EmailValidation = loadable(() => import('./components/pages/EmailValidation'));
const RecoverPassword = loadable(() => import('./components/pages/RecoverPassword'));
const SetNewPassword = loadable(() => import('./components/pages/SetNewPassword'));

const publicRoutes = [
    {
        path: '/connexion',
        component: Login,
    },
    {
        path: '/inscription',
        component: Register,
        exact: true,
    },
    {
        path: '/inscription/:token',
        component: Register,
    },
    {
        path: '/validation/:token',
        component: EmailValidation,
    },
    {
        path: '/recuperer-mot-de-passe',
        component: RecoverPassword,
    },
    {
        path: '/changement-de-mot-de-passe/:token',
        component: SetNewPassword,
    },
];

const privateRoutesThatDontNeedAccountCompletion = ['/tableau-de-bord', '/profil'];

export { sidebarMenuItems, privateRoutes, publicRoutes, privateRoutesThatDontNeedAccountCompletion };
