import {
    CREATE_CONTRACT_SUCCESS,
    CREATE_CONTRACT_FAILURE,
    GET_CONTRACTS_SUCCESS,
    GET_CONTRACTS_FAILURE,
    DELETE_CONTRACT_SUCCESS,
    DELETE_CONTRACT_FAILURE,
    PATCH_CONTRACT_SUCCESS,
    PATCH_CONTRACT_FAILURE
} from '../types/contracts.types';

const initState = () => ({
    contracts: null
});

export default (state = initState(), action) => {
    switch (action.type) {
        case CREATE_CONTRACT_SUCCESS:
            return {
                ...state
            };
        case CREATE_CONTRACT_FAILURE: {
            return {
                ...state,
                error: action.error
            };
        }
        case GET_CONTRACTS_SUCCESS:
            return {
                ...state,
                contracts: action.payload
            };
        case GET_CONTRACTS_FAILURE: {
            return {
                ...state,
                error: action.error
            };
        }
        case DELETE_CONTRACT_SUCCESS:
            return {
                ...state,
                contracts: action.payload
            };
        case DELETE_CONTRACT_FAILURE: {
            return {
                ...state,
                error: action.error
            };
        }
        case PATCH_CONTRACT_SUCCESS: {
            return {
                ...state
            };
        }
        case PATCH_CONTRACT_FAILURE: {
            return {
                ...state,
                error: action.error
            };
        }
        default:
            return state;
    }
};
