import {
    GET_ORGANIZATION_SUCCESS,
    GET_ORGANIZATION_FAILURE,
    PATCH_ORGANIZATION_SUCCESS,
    CREATE_ORGANIZATION_SUCCESS
} from '../types/organization.types';

const initState = () => ({
    organization: null
});

export default (state = initState(), action) => {
    switch (action.type) {
        case GET_ORGANIZATION_SUCCESS:
            return {
                ...state,
                organization: action.payload
            };
        case GET_ORGANIZATION_FAILURE:
            return {
                ...state,
                organization: null
            };
        case CREATE_ORGANIZATION_SUCCESS: {
            return {
                ...state,
                organization: action.payload
            };
        }
        case PATCH_ORGANIZATION_SUCCESS:
            return {
                ...state,
                organization: action.payload
            };
        default:
            return state;
    }
};
