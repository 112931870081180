export const sidebarStates = {
    DEFAULT: 'default',
    EXPANDED: 'sidebar-expanded',
    SHRINKED: 'sidebar-shrinked',
};

export const patchOperations = {
    ADD: 'add',
    UPDATE: 'update',
    SAFEDELETE: 'safe-delete',
    REMOVE: 'remove',
    DELETE: 'delete',
};

export const patchOrganizationPaths = {
    ORGANIZATION: '/organization',
    ADDRESSES: '/organization-addresses',
    CONTACTS: '/organization-contacts',
    BILLING: '/organization-billing',
    RGE_CERTIFICATES: '/organization-rge-certificates',
    INSURANCES: '/organization-insurances',
    KBIS: '/organization-kbis-extracts',
    CONSUMER_MEDIATOR: '/consumer-mediator',
};

export const patchRenovationAddressPaths = {
    HOUSEHOLD_INCOME_BONIFICATION: '/household-income-bonification',
    SOCIAL_LANDLORD_BONIFICATION: '/social-landlord-bonification',
    QPV_BONIFICATION: '/qpv-bonification',
};

export const patchCustomerPMPaths = {
    CUSTOMER_PM: '/',
    ADDRESSES: '/addresses',
    CONTACTS: '/contacts',
};

export const patchSubContractorPaths = {
    SUB_CONTRACTOR: '/sub-contractors',
    ADDRESSES: '/addresses',
    CONTACTS: '/contacts',
    RGE_CERTIFICATES: '/rge-certificates',
};

export const patchContractPaths = {
    CONTRACT: '/contracts',
    CONTRACT_AMENDMENT: '/contract-amendments',
};

export const patchQuotationPaths = {
    CUSTOMER: '/customer',
    ADDRESS: '/address',
    CONTRACT: '/contract',
};

export const organizationInsuranceWarrantyAreas = {
    WHOLE_FRANCE: { label: 'France entière', value: 'WHOLE_FRANCE' },
    METROPOLITAN_FRANCE: { label: 'France métropolitaine', value: 'METROPOLITAN_FRANCE' },
    DOM_TOM: { label: 'DOM-TOM', value: 'DOM_TOM' },
};

export const genders = {
    MALE: 'Monsieur',
    FEMALE: 'Madame',
};

export const addressModalTitles = {
    CREATE: 'Nouvelle adresse',
    EDIT: 'Modifier une adresse',
};

export const contactModalTitles = {
    CREATE: 'Nouveau contact',
    EDIT: 'Modifier un contact',
};

export const renovationAddressHousingTypes = {
    INDIVIDUAL_HOUSE: 'Maison individuelle',
    APARTMENT: 'Appartement',
    COLLECTIVE_RESIDENTIAL: 'Residentiel Collectif',
};

export const renovationAddressHousingTypesValues = {
    INDIVIDUAL_HOUSE: 'INDIVIDUAL_HOUSE',
    APARTMENT: 'APARTMENT',
    COLLECTIVE_RESIDENTIAL: 'COLLECTIVE_RESIDENTIAL',
};

export const renovationAddressTypes = {
    HOME: 'Domicile',
    WORK: 'Travaux',
};

export const customerPMActivities = {
    SOCIAL_LANDLORD: 'Bailleur social',
    COOWNED_SYNDICATE: 'Syndic de copropriété',
    OTHER: 'Autre',
};

export const renovationAddressBuildingSectors = {
    RESIDENTIAL: 'Résidentiel',
    TERTIARY: 'Tertiare',
};

export const renovationAddressBuildingSectorsValues = {
    RESIDENTIAL: 'RESIDENTIAL',
    TERTIARY: 'TERTIARY',
};

export const renovationAddressActivityAreas = {
    OFFICES: 'Bureaux',
    EDUCATION: 'Education',
    COMMERCES: 'Commerces',
    HOTELS_CATERING: 'Hôtellerie-Restauration',
    HEALTH: 'Santé',
    OTHERS: 'Autres secteurs',
};

export const renovationAddressActivityAreasValues = {
    OFFICES: 'OFFICES',
    EDUCATION: 'EDUCATION',
    COMMERCES: 'COMMERCES',
    HOTELS_CATERING: 'HOTELS_CATERING',
    HEALTH: 'HEALTH',
    OTHERS: 'OTHERS',
};

export const quotationStatuses = Object.freeze({
    CREATED: 'CREATED',
    SENT: 'SENT',
    SIGNED: 'SIGNED',
    SIGNED_AND_FINALIZED_RELATED_INVOICE: 'SIGNED_AND_FINALIZED_RELATED_INVOICE',
});

export const quotationStatusesLabels = Object.freeze({
    CREATED: 'Créé',
    SENT: 'Envoyé',
    SIGNED: 'Accepté',
    SIGNED_AND_FINALIZED_RELATED_INVOICE: 'Accepté',
});

export const quotationStatusesActionButtonLabels = Object.freeze({
    CREATED: 'Compléter',
    SENT: 'Relancer',
    SIGNED: 'Créer la facture',
    SIGNED_AND_FINALIZED_RELATED_INVOICE: 'Consulter',
});

export const customerTypes = Object.freeze({
    CUSTOMER_PP: 'CUSTOMER_PP',
    CUSTOMER_PM: 'CUSTOMER_PM',
});

export const customerPMPageActions = Object.freeze({
    CREATE: 'creer',
});

export const customerPageTypes = Object.freeze({
    CUSTOMER_PP: 'particulier',
    CUSTOMER_PM: 'morale',
});

export const administrativeContactRoles = Object.freeze({
    Dirigeant: 'Dirigeant',
    Administratif: 'Administratif',
    Financier: 'Financier',
    Commercial: 'Commercial',
    Technicien: 'Technicien',
    SAV: 'SAV',
});

export const inputTypes = Object.freeze({
    DATE: 'date',
});

export const quotationTechnicianTypes = Object.freeze({
    ORGANIZATION: 'ORGANIZATION',
    SUB_CONTRACTOR: 'SUB_CONTRACTOR',
});

export const operationInstallationTypes = Object.freeze({
    COMBLE_PERDU: 'COMBLE_PERDU',
    RAMPANT_DE_TOITURE: 'RAMPANT_DE_TOITURE',
    FENETRES_DE_TOITURE: 'FENETRES_DE_TOITURE',
    TERASSE_DE_TOITURE: 'TERASSE_DE_TOITURE',
    AUTRES_FENETRES: 'AUTRES_FENETRES',
    PORTES_FENETRES: 'PORTES_FENETRES',
    COLLECTIVE_INSTALLATION: 'COLLECTIVE_INSTALLATION',
    INDIVIDUAL_INSTALLATION: 'INDIVIDUAL_INSTALLATION',
    AIR_EAU: 'AIR_EAU',
    EAU_EAU: 'EAU_EAU',
    TYPE_A: 'TYPE_A',
    TYPE_B: 'TYPE_B',
    NONE: 'NONE',
    MUR_INTERIEUR: 'MUR_INTERIEUR',
    MUR_EXTERIEUR: 'MUR_EXTERIEUR',
    RESEAU_HYDRAULIQUE_DE_CHAUFFAGE: 'RESEAU_HYDROLIQUE_DE_CHAUFFAGE',
    RESEAU_HYDRAULIQUE_EAU_CHAUDE_SANITAIRE: 'RESEAU_HYDRAULIQUE_EAU_CHAUDE_SANITAIRE',
});

export const productTypes = Object.freeze({
    MAIN_PRODUCT: 'MAIN_PRODUCT',
    INDUCED_WORK_PRODUCT: 'INDUCED_WORK_PRODUCT',
    OTHER_PRODUCT: 'OTHER_PRODUCT',
});

export const operationHeatingTypes = Object.freeze({
    COMBUSTIBLE: 'COMBUSTIBLE',
    ELECTRICITE: 'ELECTRICITE',
});

export const productCategories = Object.freeze({
    ISOLANT: 'Isolant',
    POMPE_CHALEUR: 'Pompe à chaleur',
    TOITURE: 'Toiture ou éléments de toiture',
    FENETRE: 'Fenêtres, autres fenêtres ou porte-fenêtres',
    CHAUDIERE_INDIVIDUELLE: 'Chaudière individuelle',
    VENTILATION: 'Ventilation mécanique contrôlée (VMC) ou basse préssion (VMBP)',
    CALORIFUGEAGE: 'Calorifugeage',
});

export const productCategoriesValues = Object.freeze({
    ISOLANT: 'ISOLANT',
    POMPE_CHALEUR: 'POMPE_CHALEUR',
    TOITURE: 'TOITURE',
    FENETRE: 'FENETRE',
    CHAUDIERE_INDIVIDUELLE: 'CHAUDIERE_INDIVIDUELLE',
    VENTILATION: 'VENTILATION',
    CALORIFUGEAGE: 'CALORIFUGEAGE',
});

export const ventilationTypes = Object.freeze({
    TYPE_A: 'TYPE_A',
    TYPE_B: 'TYPE_B',
});

export const productVatRates = Object.freeze({
    METROPOLIS_NORMAL_VAT_RATE: 0.2,
    RENOVATION_SPECIFIC_VAT_RATE: 0.1,
    ZNI_NORMAL_VAT_RATE: 0.085,
    METROPOLIS_REDUCED_VAT_RATE: 0.055,
    ZNI_REDUCED_VAT_RATE: 0.021,
    ZERO_VAT_RATE: 0,
});

export const contractPricesTypesEnum = Object.freeze({
    PRECARITY: 'PRECARITY',
    CLASSIC: 'CLASSIC',
});

export const contractPricesType = Object.freeze({
    CLASSIC: 'CLASSIC',
    PRECARITY: 'PRECARITY',
});

export const invoiceStatuses = Object.freeze({
    DRAFT: 'DRAFT',
    FINALIZED: 'FINALIZED',
    SENT: 'SENT',
    PAID: 'PAID',
    CANCELLED: 'CANCELLED',
    RECTIFIED: 'RECTIFIED',
});

export const invoiceStatusesLabels = Object.freeze({
    DRAFT: 'Brouillon',
    FINALIZED: 'Finalisée',
    SENT: 'Envoyée',
    PAID: 'Payée',
    CANCELLED: 'Annulée',
    RECTIFIED: 'Rectifiée',
});

export const invoiceStatusesActionButtonLabels = Object.freeze({
    DRAFT: 'Supprimer',
    FINALIZED: 'Envoyer',
    SENT: 'Confirmer paiement',
    CANCELLED: 'Consulter',
    PAID: 'Annuler',
    RECTIFIED: 'Consulter',
});

export const dossierCEEStatuses = Object.freeze({
    PROCESSING: 'PROCESSING',
    COMPLETED: 'COMPLETED',
    SENT: 'SENT',
    CANCELLED: 'CANCELLED',
});

export const dossierCEESendingTypes = Object.freeze({
    EMAIL: 'EMAIL',
    MAIL: 'MAIL',
});

export const housingSituations = Object.freeze({
    PRECARITY: 'PRECARITY',
    GREAT_PRECARITY: 'GREAT_PRECARITY',
    CLASSIC: 'CLASSIC',
});

export const swornStatementsTypes = Object.freeze({
    CADRE_A: 'CADRE_A',
    CADRE_B: 'CADRE_B',
    CADRE_C: 'CADRE_C',
    CADRE_R1: 'CADRE_R1',
    CADRE_R2: 'CADRE_R2',
    CADRE_BS: 'CADRE_BS',
    CADRE_CD: 'CADRE_CD',
    CADRE_QPV: 'CADRE_QPV',
});

export const uploadDocumentTypes = Object.freeze({
    QUOTATION: 'QUOTATION',
    SWORN_STATEMENT: 'SWORN_STATEMENT',
    CADASTRAL_PLOT: 'CADASTRAL_PLOT',
    POST_RENOVATION_REPORT: 'POST_RENOVATION_REPORT',
    SITE_PHOTO: 'SITE_PHOTO',
});

export const uploadDocumentFileTypes = Object.freeze({
    PDF: 'PDF',
    IMAGE: 'IMAGE',
});

export const productCreationOriginTypes = Object.freeze({
    QUOTATION: 'QUOTATION',
    INVOICE: 'INVOICE',
});

export const paymentTypes = Object.freeze({
    CASH: 'CASH',
    CHEQUE: 'CHEQUE',
    TRANSFER: 'TRANSFER',
});

export const paymentTypesLabels = Object.freeze({
    CASH: 'Espèces',
    CHEQUE: 'Chèque',
    TRANSFER: 'Virement bancaire',
});
