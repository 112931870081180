import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter, Router, Switch, Route } from 'react-router-dom';
import loadable from '@loadable/component';
import { connect } from 'react-redux';

import history from '../utils/history';
import { publicRoutes } from '../routes';
import formActions from '../store/actions/ui/form';

import TypedRoute from './helpers/TypedRoute';

const Layout = loadable(() => import('./layout/Layout'));

class App extends Component {
    componentDidMount() {
        document.addEventListener('click', (evt) => {
            if (this.props.form.errorMessage) this.props.resetForm();
        });
    }

    render() {
        return (
            <BrowserRouter>
                <div className='app'>
                    <Router history={history}>
                        <Switch>
                            {publicRoutes.map((publicRoute, index) => (
                                <TypedRoute type='public' key={index} path={publicRoute.path} component={publicRoute.component} exact={!!publicRoute.exact} />
                            ))}
                            <Route path='/' component={Layout} />
                        </Switch>
                    </Router>
                </div>
            </BrowserRouter>
        );
    }
}

App.propTypes = {
    form: PropTypes.shape({
        errorMessage: PropTypes.string,
    }).isRequired,
    resetForm: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    form: state.ui.form,
});

export default connect(mapStateToProps, { resetForm: formActions.resetForm })(App);
