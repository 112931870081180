import { SHOW_CONFIRM_DAILOG, SHOW_SUCCESS_DIALOG, SHOW_FAILURE_DIALOG, HIDE_DIALOGS } from '../../types/ui/dialogs';

const initState = () => ({
    showConfirmDialog: false,
    showSuccessDialog: false,
    showFailureDialog: false,
});

export default (state = initState(), action) => {
    switch (action.type) {
        case SHOW_CONFIRM_DAILOG:
            return {
                showConfirmDialog: true,
                message: action.payload.message,
                onConfirm: action.payload.onConfirm,
            };
        case SHOW_SUCCESS_DIALOG:
            return {
                showSuccessDialog: true,
                message: action.payload.message,
            };
        case SHOW_FAILURE_DIALOG:
            return {
                showFailureDialog: true,
                message: action.payload.message,
                messages: action.payload.messages,
            };
        case HIDE_DIALOGS:
            return {
                showConfirmDialog: false,
            };
        default:
            return state;
    }
};
