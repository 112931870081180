import { CREATE_CUSTOMER_PM_SUCCESS, GET_CUSTOMER_PM_SUCCESS, GET_CUSTOMERS_PM_SUCCESS, PATCH_CUSTOMER_PM_SUCCESS, DELETE_CUSTOMER_PM_SUCCESS } from '../types/customer-pm.types';
import { GET_QPVS_SUCCESS } from '../types/renovation-address.types';

function initState() {
    return {
        customerPM: {},
        customersPM: [],
        qpvs: [],
    };
}

export default function (state = initState(), action) {
    switch (action.type) {
        case CREATE_CUSTOMER_PM_SUCCESS:
            return {
                ...state,
                customerPM: action.payload.customerPM,
            };

        case GET_CUSTOMER_PM_SUCCESS:
            return {
                ...state,
                customerPM: action.payload.customerPM,
            };

        case GET_CUSTOMERS_PM_SUCCESS:
            return {
                ...state,
                customersPM: action.payload.customersPM,
            };

        case PATCH_CUSTOMER_PM_SUCCESS:
            return {
                ...state,
                customerPM: action.payload.customerPM,
            };

        case DELETE_CUSTOMER_PM_SUCCESS:
            return {
                ...state,
                customersPM: state.customersPM.filter((customerPM) => customerPM.uuid !== action.payload.customerPM.uuid),
            };

        case GET_QPVS_SUCCESS:
            return {
                ...state,
                qpvs: action.payload.qpvs,
            };

        default:
            return state;
    }
}
