export const CREATE_QUOTATION_REQUEST = 'CREATE_QUOTATION_REQUEST';
export const CREATE_QUOTATION_SUCCESS = 'CREATE_QUOTATION_SUCCESS';
export const CREATE_QUOTATION_FAILURE = 'CREATE_QUOTATION_FAILURE';

export const GET_QUOTATIONS_REQUEST = 'GET_QUOTATIONS_REQUEST';
export const GET_QUOTATIONS_SUCCESS = 'GET_QUOTATIONS_SUCCESS';
export const GET_QUOTATIONS_FAILURE = 'GET_QUOTATIONS_FAILURE';

export const GET_QUOTATION_REQUEST = 'GET_QUOTATION_REQUEST';
export const GET_QUOTATION_SUCCESS = 'GET_QUOTATION_SUCCESS';
export const GET_QUOTATION_FAILURE = 'GET_QUOTATION_FAILURE';

export const PATCH_QUOTATION_REQUEST = 'PATCH_QUOTATION_REQUEST';
export const PATCH_QUOTATION_SUCCESS = 'PATCH_QUOTATION_SUCCESS';
export const PATCH_QUOTATION_FAILURE = 'PATCH_QUOTATION_FAILURE';

export const ADD_OPERATION = 'ADD_OPERATION';
export const UPDATE_OPERATION = 'UPDATE_OPERATION';
export const RESET_OPERATION = 'RESET_OPERATION';
export const DELETE_OPERATION = 'DELETE_OPERATION';

export const ADD_PRODUCT = 'ADD_PRODUCT';
export const UPDATE_MAIN_PRODUCT = 'UPDATE_MAIN_PRODUCT';
export const UPDATE_OTHER_PRODUCT = 'UPDATE_OTHER_PRODUCT';
export const UPDATE_LAST_OTHER_PRODUCT = 'UPDATE_LAST_OTHER_PRODUCT';
export const DELETE_OTHER_PRODUCT = 'DELETE_OTHER_PRODUCT';
export const DELETE_ALL_OTHER_PRODUCTS = 'DELETE_ALL_OTHER_PRODUCTS';

export const UPDATE_OPERATION_INSTALLATION_DATE = 'UPDATE_OPERATION_INSTALLATION_DATE';
export const UPDATE_OPERATION_TECHNICIAN = 'UPDATE_OPERATION_TECHNICIAN';

export const PRE_SAVE_QUOTATION_REQUEST = 'PRE_SAVE_QUOTATION_REQUEST';
export const PRE_SAVE_QUOTATION_SUCCESS = 'PRE_SAVE_QUOTATION_SUCCESS';
export const PRE_SAVE_QUOTATION_FAILURE = 'PRE_SAVE_QUOTATION_FAILURE';

export const SAVE_QUOTATION_REQUEST = 'SAVE_QUOTATION_REQUEST';
export const SAVE_QUOTATION_SUCCESS = 'SAVE_QUOTATION_SUCCESS';
export const SAVE_QUOTATION_FAILURE = 'SAVE_QUOTATION_FAILURE';

export const UPDATE_QUOTATION = 'UPDATE_QUOTATION';

export const DELETE_QUOTATION_REQUEST = 'DELETE_QUOTATION_REQUEST';
export const DELETE_QUOTATION_SUCCESS = 'DELETE_QUOTATION_SUCCESS';
export const DELETE_QUOTATION_FAILURE = 'DELETE_QUOTATION_FAILURE';

export const GET_QUOTATIONS_ANALYTICS_REQUEST = 'GET_QUOTATIONS_ANALYTICS_REQUEST';
export const GET_QUOTATIONS_ANALYTICS_SUCCESS = 'GET_QUOTATIONS_ANALYTICS_SUCCESS';
export const GET_QUOTATIONS_ANALYTICS_FAILURE = 'GET_QUOTATIONS_ANALYTICS_FAILURE';

export const CHECK_QUOTATION_INFORMATION_REDUNDANCY_REQUEST = 'CHECK_QUOTATION_INFORMATION_REDUNDANCY_REQUEST';
export const CHECK_QUOTATION_INFORMATION_REDUNDANCY_SUCCESS = 'CHECK_QUOTATION_INFORMATION_REDUNDANCY_SUCCESS';
export const CHECK_QUOTATION_INFORMATION_REDUNDANCY_FAILURE = 'CHECK_QUOTATION_INFORMATION_REDUNDANCY_FAILURE';

export const SEND_QUOTATION_BY_EMAIL_REQUEST = 'SEND_QUOTATION_BY_EMAIL_REQUEST';
export const SEND_QUOTATION_BY_EMAIL_SUCCESS = 'SEND_QUOTATION_BY_EMAIL_SUCCESS';
export const SEND_QUOTATION_BY_EMAIL_FAILURE = 'SEND_QUOTATION_BY_EMAIL_FAILURE';
