import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@renolib/renolib-ui-kit/dist/index.css';
import { Provider } from 'react-redux';
import { createStore, compose, applyMiddleware } from 'redux';
import reduxThunk from 'redux-thunk';
import { createLogger } from 'redux-logger';

import './styles/react-date-picker.css';
import './index.css';
import * as serviceWorker from './serviceWorker';
import mousePositionHelper from './utils/mouse-position-helper';

import App from './components/App';
import LayoutProvider from './components/providers/Layout';
import reducers from './store/reducers';

const loggerMiddleware = createLogger({
    collapsed: (getState, action, logEntry) => !logEntry.error,
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducers, composeEnhancers(applyMiddleware(reduxThunk, loggerMiddleware)));

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <LayoutProvider>
                <App />
            </LayoutProvider>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
mousePositionHelper.handleMouseMove();

export { store };
