import { START_REQUESTING, STOP_REQUESTING, RESET_FORM } from '../../types/ui/form';

const startRequesting = ({ fromPublicRoute = false } = {}) => ({
    type: START_REQUESTING,
    payload: { fromPublicRoute },
});

const stopRequesting = ({ errorMessage, successMessage } = {}) => ({
    type: STOP_REQUESTING,
    payload: { errorMessage, successMessage },
});

const resetForm = () => ({
    type: RESET_FORM,
});

export default {
    startRequesting,
    stopRequesting,
    resetForm,
};
