import {
    GET_RGE_CERTIFICATES_REQUEST,
    GET_RGE_CERTIFICATES_SUCCESS,
    GET_RGE_CERTIFICATES_FAILURE
} from '../types/rge-certificates-finder.types';

const initState = () => ({});

export default (state = initState(), action) => {
    switch (action.type) {
        case GET_RGE_CERTIFICATES_REQUEST:
            return {
                [action.payload.instanceKey]: {
                    loading: true,
                    rgeCertificates: []
                }
            };
        case GET_RGE_CERTIFICATES_SUCCESS: {
            return {
                ...state,
                [action.payload.instanceKey]: {
                    loading: false,
                    rgeCertificates: action.payload.rgeCertificates
                }
            };
        }
        case GET_RGE_CERTIFICATES_FAILURE: {
            return {
                ...state,
                [action.payload.instanceKey]: {
                    loading: false,
                    rgeCertificates: []
                }
            };
        }
        default:
            return state;
    }
};
