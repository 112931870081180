import {
    CREATE_INVOICE_SUCCESS,
    CREATE_INVOICE_FAILURE,
    GET_INVOICE_SUCCESS,
    GET_INVOICE_FAILURE,
    GET_INVOICES_SUCCESS,
    GET_INVOICES_FAILURE,
    SAVE_INVOICE_SUCCESS,
    SAVE_INVOICE_FAILURE,
    DELETE_INVOICE_SUCCESS,
    DELETE_INVOICE_FAILURE,
    CANCEL_INVOICE_SUCCESS,
    CANCEL_INVOICE_FAILURE,
    UPDATE_INVOICE_FAILURE,
    UPDATE_INVOICE_SUCCESS,
    UPDATE_INVOICE_OPERATION_INSTALLATION_DATE,
    UPDATE_OPERATION_INSTALLATION_END_DATE,
    UPDATE_OPERATION_PRODUCT_QUANTITY,
    UPDATE_OPERATION_OTHER_PRODUCT_QUANTITY,
    UPDATE_INVOICE_ISSUE_DATE_REQUEST,
    UPDATE_INVOICE_OTHER_PRODUCT,
    UPDATE_INVOICE_MAIN_PRODUCT,
    ADD_INVOICE_OTHER_PRODUCT,
    DELETE_INVOICE_OTHER_PRODUCT,
    SEND_INVOICE_BY_EMAIL_SUCCESS,
    SEND_INVOICE_BY_EMAIL_FAILURE,
} from '../types/invoice.types';
import { productTypes, productCreationOriginTypes } from '../../utils/enums';

const initState = () => ({
    invoice: {},
    invoices: [],
});

export default (state = initState(), action) => {
    switch (action.type) {
        case CREATE_INVOICE_SUCCESS:
            return {
                ...state,
                invoice: action.payload,
            };
        case CREATE_INVOICE_FAILURE:
            return {
                ...state,
            };
        case GET_INVOICE_SUCCESS:
            return {
                ...state,
                invoice: action.payload,
            };
        case GET_INVOICE_FAILURE:
            return {
                ...state,
                error: action.error,
            };
        case GET_INVOICES_SUCCESS:
            return {
                ...state,
                invoices: action.payload,
            };
        case GET_INVOICES_FAILURE:
            return {
                ...state,
                error: action.error,
            };
        case SAVE_INVOICE_SUCCESS:
            return {
                ...state,
                invoice: action.payload,
            };
        case SAVE_INVOICE_FAILURE:
            return {
                ...state,
                error: action.error,
            };

        case DELETE_INVOICE_SUCCESS:
            return {
                ...state,
                invoices: state.invoices.filter((invoice) => invoice.uuid !== action.payload.uuid),
            };
        case DELETE_INVOICE_FAILURE:
            return {
                ...state,
                error: action.error,
            };

        case CANCEL_INVOICE_SUCCESS:
            return {
                ...state,
                invoice: action.payload,
            };
        case CANCEL_INVOICE_FAILURE:
            return {
                ...state,
                error: action.error,
            };

        case UPDATE_INVOICE_SUCCESS:
            return {
                ...state,
                invoice: action.payload,
            };
        case UPDATE_INVOICE_FAILURE:
            return {
                ...state,
                error: action.error,
            };

        case SEND_INVOICE_BY_EMAIL_SUCCESS:
            return {
                ...state,
                invoice: action.payload.invoice,
            };

        case SEND_INVOICE_BY_EMAIL_FAILURE:
            return {
                ...state,
                error: action.error,
            };

        case UPDATE_INVOICE_OPERATION_INSTALLATION_DATE:
            return {
                ...state,
                invoice: {
                    ...state.invoice,
                    operations: state.invoice.operations.map((operation) => {
                        if (operation.uuid === action.payload.uuidOperation) {
                            return {
                                ...operation,
                                installationDate: action.payload.installationDate,
                            };
                        }

                        return operation;
                    }),
                },
            };

        case UPDATE_OPERATION_INSTALLATION_END_DATE:
            return {
                ...state,
                invoice: {
                    ...state.invoice,
                    operations: state.invoice.operations.map((operation) => {
                        if (operation.uuid === action.payload.uuidOperation) {
                            return {
                                ...operation,
                                installationEndDate: action.payload.installationEndDate,
                            };
                        }

                        return operation;
                    }),
                },
            };

        case UPDATE_OPERATION_PRODUCT_QUANTITY:
            return {
                ...state,
                invoice: {
                    ...state.invoice,
                    operations: state.invoice.operations.map((operation) => {
                        if (operation.uuid === action.payload.uuidOperation) {
                            return {
                                ...operation,
                                products: operation.products.map((product) => {
                                    if (product.uuid === action.payload.uuidProduct) {
                                        Object.assign(product, { totalPrice: action.payload.quantity * product.price });
                                        return {
                                            ...product,
                                            quantity: action.payload.quantity,
                                        };
                                    }
                                    return product;
                                }),
                            };
                        }

                        return operation;
                    }),
                },
            };

        case UPDATE_OPERATION_OTHER_PRODUCT_QUANTITY:
            return {
                ...state,
                invoice: {
                    ...state.invoice,
                    operations: state.invoice.operations.map((operation) => {
                        if (operation.uuid === action.payload.uuidOperation) {
                            return {
                                ...operation,
                                products: operation.products.map((product) => {
                                    if (product.uuid === action.payload.uuidProduct) {
                                        const updatedProduct = {
                                            ...product,
                                            quantity: action.payload.quantity,
                                        };
                                        if (['quantity', 'price'].every((property) => Object.keys(updatedProduct).includes(property))) {
                                            updatedProduct.totalPrice = updatedProduct.quantity * updatedProduct.price;
                                        }

                                        return updatedProduct;
                                    }

                                    return product;
                                }),
                            };
                        }

                        return operation;
                    }),
                },
            };

        case UPDATE_INVOICE_ISSUE_DATE_REQUEST:
            return {
                ...state,
                invoice: {
                    ...state.invoice,
                    invoiceIssueDate: action.payload.invoiceIssueDate,
                },
            };

        case UPDATE_INVOICE_OTHER_PRODUCT:
            return {
                ...state,
                invoice: {
                    ...state.invoice,
                    operations: state.invoice.operations.map((operation) => {
                        if (operation.uuid === action.payload.operationUUID) {
                            return {
                                ...operation,
                                products: operation.products.map((product) => {
                                    if (product.uuid === action.payload.productUUID) {
                                        const updatedProduct = {
                                            ...product,
                                            ...action.payload.updates,
                                        };
                                        if (['quantity', 'price'].every((property) => Object.keys(updatedProduct).includes(property))) {
                                            updatedProduct.totalPrice = updatedProduct.quantity * updatedProduct.price;
                                        }
                                        return updatedProduct;
                                    }

                                    return product;
                                }),
                            };
                        }

                        return operation;
                    }),
                },
            };

        case UPDATE_INVOICE_MAIN_PRODUCT:
            return {
                ...state,
                invoice: {
                    ...state.invoice,
                    operations: state.invoice.operations.map((operation) => {
                        if (operation.uuid === action.payload.operationUUID) {
                            return {
                                ...operation,
                                products: operation.products.map((product) => {
                                    if (product.product.type === productTypes.MAIN_PRODUCT) {
                                        const updatedMainProduct = {
                                            ...product,
                                            ...action.payload.updates,
                                        };
                                        if (['quantity', 'price'].every((property) => Object.keys(updatedMainProduct).includes(property))) {
                                            updatedMainProduct.totalPrice = updatedMainProduct.quantity * updatedMainProduct.price;
                                        }

                                        return updatedMainProduct;
                                    }

                                    return product;
                                }),
                            };
                        }

                        return operation;
                    }),
                },
            };

        case ADD_INVOICE_OTHER_PRODUCT:
            return {
                ...state,
                invoice: {
                    ...state.invoice,
                    operations: state.invoice.operations.map((operation) => {
                        if (operation.uuid === action.payload.operationUUID) {
                            const { products = [] } = operation;
                            products.push({ ...action.payload.product, creationOrigin: productCreationOriginTypes.INVOICE });

                            return {
                                ...operation,
                                products,
                            };
                        }

                        return operation;
                    }),
                },
            };

        case DELETE_INVOICE_OTHER_PRODUCT: {
            return {
                ...state,
                invoice: {
                    ...state.invoice,
                    operations: state.invoice.operations.map((operation) => {
                        if (operation.uuid === action.payload.operationUUID) {
                            return {
                                ...operation,
                                products: operation.products.filter(({ uuid }) => uuid !== action.payload.productUUID),
                            };
                        }

                        return operation;
                    }),
                },
            };
        }

        default:
            return state;
    }
};
