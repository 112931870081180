export const GET_SUB_CONTRACTORS_REQUEST = 'GET_SUB_CONTRACTORS_REQUEST';
export const GET_SUB_CONTRACTORS_SUCCESS = 'GET_SUB_CONTRACTORS_SUCCESS';
export const GET_SUB_CONTRACTORS_FAILURE = 'GET_SUB_CONTRACTORS_FAILURE';

export const CREATE_SUB_CONTRACTOR_REQUEST = 'CREATE_SUB_CONTRACTOR_REQUEST';
export const CREATE_SUB_CONTRACTOR_SUCCESS = 'CREATE_SUB_CONTRACTOR_SUCCESS';
export const CREATE_SUB_CONTRACTOR_FAILURE = 'CREATE_SUB_CONTRACTOR_FAILURE';

export const GET_SUB_CONTRACTOR_REQUEST = 'GET_SUB_CONTRACTOR_REQUEST';
export const GET_SUB_CONTRACTOR_SUCCESS = 'GET_SUB_CONTRACTOR_SUCCESS';
export const GET_SUB_CONTRACTOR_FAILURE = 'GET_SUB_CONTRACTOR_FAILURE';

export const UPDATE_SUB_CONTRACTOR_REQUEST = 'UPDATE_SUB_CONTRACTOR_REQUEST';
export const UPDATE_SUB_CONTRACTOR_SUCCESS = 'UPDATE_SUB_CONTRACTOR_SUCCESS';
export const UPDATE_SUB_CONTRACTOR_FAILURE = 'UPDATE_SUB_CONTRACTOR_FAILURE';

export const DELETE_SUB_CONTRACTOR_REQUEST = 'DELETE_SUB_CONTRACTOR_REQUEST';
export const DELETE_SUB_CONTRACTOR_SUCCESS = 'DELETE_SUB_CONTRACTOR_SUCCESS';
export const DELETE_SUB_CONTRACTOR_FAILURE = 'DELETE_SUB_CONTRACTOR_FAILURE';

export const PATCH_SUB_CONTRACTOR_REQUEST = 'PATCH_SUB_CONTRACTOR_REQUEST';
export const PATCH_SUB_CONTRACTOR_SUCCESS = 'PATCH_SUB_CONTRACTOR_SUCCESS';
export const PATCH_SUB_CONTRACTOR_FAILURE = 'PATCH_SUB_CONTRACTOR_FAILURE';
