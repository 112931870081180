import {
    GET_SUB_CONTRACTORS_SUCCESS,
    GET_SUB_CONTRACTORS_FAILURE,
    CREATE_SUB_CONTRACTOR_SUCCESS,
    GET_SUB_CONTRACTOR_SUCCESS,
    GET_SUB_CONTRACTOR_FAILURE,
    UPDATE_SUB_CONTRACTOR_SUCCESS,
    PATCH_SUB_CONTRACTOR_SUCCESS,
} from '../types/sub-contractors.types';

const initState = () => ({
    subContractors: null,
});

export default (state = initState(), action) => {
    switch (action.type) {
        case GET_SUB_CONTRACTORS_SUCCESS:
            return {
                ...state,
                subContractors: action.payload,
            };
        case GET_SUB_CONTRACTORS_FAILURE:
            return {
                ...state,
                subContractors: null,
            };
        case CREATE_SUB_CONTRACTOR_SUCCESS:
            return {
                ...state,
                subContractor: action.payload,
            };
        case GET_SUB_CONTRACTOR_SUCCESS:
            return {
                ...state,
                subContractor: action.payload,
            };
        case GET_SUB_CONTRACTOR_FAILURE:
            return {
                ...state,
                subContractor: null,
            };
        case UPDATE_SUB_CONTRACTOR_SUCCESS:
            return {
                ...state,
                subContractor: action.payload,
            };
        case PATCH_SUB_CONTRACTOR_SUCCESS:
            return {
                ...state,
                subContractor: action.payload,
            };
        default:
            return state;
    }
};
