export const GET_DOSSIERS_CEE_REQUEST = 'GET_DOSSIERS_CEE_REQUEST';
export const GET_DOSSIERS_CEE_SUCCESS = 'GET_DOSSIERS_CEE_SUCCESS';
export const GET_DOSSIERS_CEE_FAILURE = 'GET_DOSSIERS_CEE_FAILURE';

export const GET_DOSSIER_CEE_REQUEST = 'GET_DOSSIER_CEE_REQUEST';
export const GET_DOSSIER_CEE_SUCCESS = 'GET_DOSSIER_CEE_SUCCESS';
export const GET_DOSSIER_CEE_FAILURE = 'GET_DOSSIER_CEE_FAILURE';

export const UPDATE_DOSSIER_CEE_REQUEST = 'UPDATE_DOSSIER_CEE_REQUEST';
export const UPDATE_DOSSIER_CEE_SUCCESS = 'UPDATE_DOSSIER_CEE_SUCCESS';
export const UPDATE_DOSSIER_CEE_FAILURE = 'UPDATE_DOSSIER_CEE_FAILURE';

export const DOWNLOAD_DOSSIER_CEE_REQUEST = 'DOWNLOAD_DOSSIER_CEE_REQUEST';
export const DOWNLOAD_DOSSIER_CEE_SUCCESS = 'DOWNLOAD_DOSSIER_CEE_SUCCESS';
export const DOWNLOAD_DOSSIER_CEE_FAILURE = 'DOWNLOAD_DOSSIER_CEE_FAILURE';

export const SEND_DOSSIER_CEE_REQUEST = 'SEND_DOSSIER_CEE_REQUEST';
export const SEND_DOSSIER_CEE_SUCCESS = 'SEND_DOSSIER_CEE_SUCCESS';
export const SEND_DOSSIER_CEE_FAILURE = 'SEND_DOSSIER_CEE_FAILURE';

export const GET_DOSSIERS_CEE_ANALYTICS_REQUEST = 'GET_DOSSIERS_CEE_ANALYTICS_REQUEST';
export const GET_DOSSIERS_CEE_ANALYTICS_SUCCESS = 'GET_DOSSIERS_CEE_ANALYTICS_SUCCESS';
export const GET_DOSSIERS_CEE_ANALYTICS_FAILURE = 'GET_DOSSIERS_CEE_ANALYTICS_FAILURE';

export const GET_RANDOM_DOSSIER_CEE = 'GET_RANDOM_DOSSIER_CEE';
